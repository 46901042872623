<style lang="scss" scoped>
.page-asset-list {
    .asset-image {
        width: 80px;
        height: 80px;
    }
}
</style>

<template>
    <div class="page-asset-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()"
                        size="medium">新增</el-button>
                </div>
                <div class="fr">
                    <el-cascader :ref="refCascader" class="mrgr5 mrgb5" popper-class="c-no-cascader-radio" size="medium" v-model="listQuery.categoryId" :options="allCategorys" placeholder="请选择资产类别" 
                        :props="defaultProps" filterable clearable @change="_search()"></el-cascader>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="mini" @cell-dblclick="dbClick">
                <el-table-column label="资产名称" prop="name" width="250"></el-table-column>
                <el-table-column label="【ID】分类名称" min-width="130">
                    <template slot-scope="scope">
                        <span class="col9">【{{ scope.row.category && scope.row.category.id }}】</span>
                        {{ scope.row.category && scope.row.category.name }}
                    </template>
                </el-table-column>
                <el-table-column label="条形码" prop="barCode"></el-table-column>
                <el-table-column label="资产图片" min-width="106">
                    <template slot-scope="scope">
                        <el-image class="asset-image" v-if="scope.row.imageUrl"
                            :src="uploadBaseUrl + scope.row.imageUrl" 
                            :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="是否有序列号" min-width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" size="medium" v-if="scope.row.hasSerialNumber">是</el-tag>
                        <el-tag type="info" size="medium" v-else>否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="creationTime" :formatter="tableDateFormat" min-width="100"></el-table-column>
                <el-table-column label="操作" align="center" width="100" :fixed="$root.isPc ? 'right' : false">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <!--<el-dropdown-item @click.native="_query(scope.row)">查看详情</el-dropdown-item>-->
                                <el-dropdown-item @click.native="_edit(scope.row)">编辑</el-dropdown-item>
                                <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--详情-->
        <!--<detail :ref="refDetail"></detail>-->
        <!--编辑-->
        <edit :ref="refEdit" @refreshData="getRefreshData"></edit>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
// import detail from "./components/detail"
import edit from "./components/edit"
import moment from "moment"
export default {
    name: "pageAssetList",
    components: { edit },
    data() {
        let that = this
        return {
            refCascader: "refAssetListCascader",
            refDetail: "refAssetToDetail",
            refEdit: "refAssetToEdit",
            allCategorys: [],
            tableData: [],
            total: null,
            listQuery: {
                categoryId: null,
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            }
        }
    },
    mounted() {
        this.getAssetTypes()
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        getAssetTypes() {
            funInfomation.getAssetCategory().then(res => {
                window.$common.closeFullLoading()
                this.allCategorys = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funInfomation.getAssetList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        // 判断是创建还是更新，执行刷新操作
        getRefreshData(type) {
            if (type === 1) {
                this.getDataList()
            } else {
                this._search()
            }
        },
        dbClick(row) {
            this._edit(row)
        },
        _query(row) {
            this.$refs[this.refDetail].open(row)
        },
        _edit(row) {
            this.$refs[this.refEdit].open(row)
        },
        _delete(row) {
            // 删除
            this.confirm("确定要删除当前资产数据吗？").then(()=>{
                window.$common.fullLoading()
                funInfomation.delAsset({id: row.id}).then(() => {
                    this.getDataList()
                })
            })
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        _search() { 
            this.closeCascader()
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>